import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,3,4,2,5];

export const dictionary = {
		"/(main)": [~13,[3]],
		"/about-us": [24],
		"/(settings)/account_settings": [~22,[4]],
		"/(auth)/activate": [~7,[2]],
		"/admin/modals": [25,[5,6]],
		"/admin/modals/new/mobile": [28,[5,6]],
		"/admin/modals/new/tv": [29,[5,6]],
		"/admin/modals/[id]": [26,[5,6]],
		"/admin/modals/[id]/preview": [27,[5,6]],
		"/channels": [~30],
		"/(main)/channel/[channel]": [~15,[3]],
		"/(main)/channel/[channel]/[show]": [~16,[3]],
		"/(settings)/contact-us": [~23,[4]],
		"/(main)/id/[id]": [~17,[3]],
		"/(main)/location/[location]": [~18,[3]],
		"/polls": [~31],
		"/premium": [~32],
		"/(main)/results": [~19,[3]],
		"/(auth)/signin": [~8,[2]],
		"/(auth)/signin/checkinbox": [~9,[2]],
		"/(auth)/signin/email": [~10,[2]],
		"/(auth)/signin/failed": [~11,[2]],
		"/(auth)/signin/success": [~12,[2]],
		"/(main)/tag/[tag]": [~20,[3]],
		"/(main)/v/[slug]": [~21,[3]],
		"/(main)/[category]": [~14,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';